/* eslint-disable no-unused-vars */
import React from "react"
import {graphql} from "gatsby"
import {
  Button,
  HeaderBack,
  HeadingXL,
  Image,
  Layout,
  SEO,
  TextBody,
} from "../components"

export default function About({data}) {
  return (
    <>
      <SEO title="About" />
      <HeaderBack />
      <Layout>
        <HeadingXL>ABOUT</HeadingXL>
        {/* <Image fluid={data.RandomPhoto.childImageSharp.fluid} /> */}
        <TextBody>
        {/* I went to highschool with a passion for programming.
        <br/>
        An introductory finance class showed me a whole another world.
        <br/>
        Currently I am learning about the financial markets and attempting to extract alpha.
        <br/>
        Aspiring to become a quant. */}
        A mysterious boy.
        </TextBody>
        <Button href="mailto:hi&#64;ihitthose.com">Hit me up.</Button>
      </Layout>
    </>
  )
}

export const data = graphql`
  query {
    RandomPhoto: file(relativePath: {eq: "assets/images/ithitthose.png"}) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
